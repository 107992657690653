// features/user/MobileMenu.tsx
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Divider,
} from "@mui/material";
import {
  ExitToApp as ExitToAppIcon,
  Description as DescriptionIcon,
  FileCopy as FileCopyIcon,
  Checklist as ChecklistIcon,
  PlaylistAddCheckCircle as PlaylistAddCheckCircleIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  PersonSearch as PersonSearchIcon,
  PersonAddAlt as PersonAddAltIcon,
  PersonAddAlt1 as PersonAddAlt1Icon,
  PeopleOutline as PeopleOutlineIcon,
  PeopleAlt as PeopleAltIcon,
  ContactPage as ContactPageIcon
} from "@mui/icons-material";
import appsIcon from "./assets/icons/apps-2.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "features/auth/hooks/useAuth";
import mobileLogo from "features/user/assets/wp_logo.png";

const CustomMenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="5" width="18" height="2" rx="1" fill="currentColor" />
    <rect x="3" y="11" width="12" height="2" rx="1" fill="currentColor" />
    <rect x="3" y="17" width="18" height="2" rx="1" fill="currentColor" />
  </svg>
);

const MobileMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [pathToNavigate, setPathToNavigate] = useState("");

  const isOnPCTPage = location.pathname.startsWith("/user/apps/pct");
  const isOnCVCPage = location.pathname.startsWith("/user/apps/cvc");

  const menuItems = [
    {
      path: "/user/apps",
      label: "App Library",
      icon: (
        <img src={appsIcon} alt="Apps Icon" style={{ width: 32, height: 32 }} />
      ),
    },
  ];

  // Untermenüpunkte der PCT-App
  const pctMenuItems = [
    {
      path: "/user/apps/pct/pctx1",
      label: "PCT x1",
      icon: <DescriptionIcon />,
    },
    {
      path: "/user/apps/pct/pctx3",
      label: "PCT x3",
      icon: <FileCopyIcon />,
    },
    {
      path: "/user/apps/pct/pctcs",
      label: "PCT CS",
      icon: <ChecklistIcon />,
    },
    {
      path: "/user/apps/pct/pctsiü",
      label: "PCT SIÜ",
      icon: <PlaylistAddCheckCircleIcon />,
    },
    {
      path: "/user/apps/pct/ausschreibungen",
      label: "AUS",
      icon: <ContentPasteSearchIcon />,
    },
    {
      path: "/user/apps/pct/msg",
      label: "MSG",
      icon: <ContactPageIcon />,
    }
  ];

    // Untermenüpunkte der CVC-App
    const cvcMenuItems = [
      {
        path: "/user/apps/cvc/individuell-ar",
        label: "IND A+R",
        icon: <PersonSearchIcon />,
      },
      {
        path: "/user/apps/cvc/extern-ar",
        label: "EXT A+R",
        icon: <PersonAddAltIcon />,
      },
      {
        path: "/user/apps/cvc/intern-ar",
        label: "INT A+R",
        icon: <PersonAddAlt1Icon />,
      },
      {
        path: "/user/apps/cvc/extern-ea",
        label: "EXT EA",
        icon: <PeopleOutlineIcon />,
      },
      {
        path: "/user/apps/cvc/intern-ea",
        label: "INT EA",
        icon: <PeopleAltIcon />,
      }
    ];

  useEffect(() => {
    if (!isOpen && pathToNavigate) {
      navigate(pathToNavigate);
      setPathToNavigate("");
    }
  }, [isOpen, pathToNavigate, navigate]);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (path: string) => {
    setPathToNavigate(path);
    setIsOpen(false);
  };

  const getListItemStyles = (path: string) =>
    location.pathname === path ? { color: "#000", fontWeight: "bold" } : { color: "#555" };

  const renderMenuItem = (item: any) => (
    <ListItem
      button
      key={item.path}
      onClick={() => handleMenuClick(item.path)}
      sx={getListItemStyles(item.path)}
    >
      <ListItemIcon sx={getListItemStyles(item.path)}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.label} />
    </ListItem>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "#f6f6f6",
          boxShadow: "none",
          height: 60,
          backgroundColor: "#f6f6f6",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            minHeight: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0",
            backgroundColor: "#f6f6f6",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              ml: 1,
              mr: 1,
            }}
          >
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{
                color: "#222222",
              }}
            >
              <CustomMenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 2,
        }}
      >
        <div
          style={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            component="img"
            sx={{ maxWidth: 60, padding: 2, pb: 4 }}
            alt="Logo"
            src={mobileLogo}
            onClick={() => {
              handleMenuClick("/user/apps");
            }}
            style={{ cursor: "pointer" }}
          />
          <List>{menuItems.map(renderMenuItem)}</List>

          {isOnPCTPage && (
            <>
              <Divider sx={{ my: 1 }} />
              <List>
                {pctMenuItems.map(renderMenuItem)}
              </List>
            </>
          )}

          {isOnCVCPage && (
            <>
              <Divider sx={{ my: 1 }} />
              <List>
                {cvcMenuItems.map(renderMenuItem)}
              </List>
            </>
          )}

          <Box sx={{ height: 16 }} />
          <List style={{ marginTop: "auto" }}>
            <ListItem
              button
              key="logout"
              onClick={() => {
                handleMenuClick("/login");
                logout();
              }}
              sx={getListItemStyles("logout")}
            >
              <ListItemIcon sx={getListItemStyles("logout")}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileMenu;
