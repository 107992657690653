// src/AppRoutes.tsx
import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";
import LoadingIndicator from "components/LoadingIndicator";
import PrivateRoute from "features/auth/components/guards/PrivateRoute";
import Dashboard from "features/user/Dashboard";

// Öffentliche Seiten
const LoginPage = lazy(() => import("features/auth/pages/LoginPage"));
const TermsOfUsePage = lazy(() => import("views/TermsOfUsePage"));
const PrivacyPolicyPage = lazy(() => import("views/PrivacyPolicyPage"));

// User Seiten und Komponenten
const AppsOverviewPage = lazy(
  () => import("features/user/pages/AppsOverviewPage")
);
const CVCPage = lazy(() => import("features/user/pages/CVCPage"));
const IGTPage = lazy(() => import("features/user/pages/IGTPage"));
const PCTPage = lazy(() => import("features/user/pages/PCTPage"));

// PCT Unterseiten
const PCTx1 = lazy(
  () => import("features/apps/PCT/pctx1/components/PCTx1")
);
const PCTx3 = lazy(
  () => import("features/apps/PCT/pctx3/components/PCTx3")
);
const PCTCS = lazy(
  () => import("features/apps/PCT/pctcs/components/PCTCS")
);
const PCTSIÜ = lazy(
  () => import("features/apps/PCT/pctsiü/components/PCTSIÜ")
);
const Tender = lazy(
  () => import("features/apps/PCT/ausschreibung/components/Tender")
);
const MSG = lazy(
  () => import("features/apps/PCT/msg/MSG")
);

// CVC Unterseiten
const INDAR = lazy(
  () => import("features/apps/CVC/individuellAR/components/INDAR")
);
const EXTAR = lazy(
  () => import("features/apps/CVC/externAR/components/EXTAR")
);
const INTAR = lazy(
  () => import("features/apps/CVC/internAR/components/INTAR")
);
const EXTEA = lazy(
  () => import("features/apps/CVC/externEA/components/EXTEA")
);
const INTEA = lazy(
  () => import("features/apps/CVC/internEA/components/INTEA")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <LoginPage />, index: true },
      { path: "login", element: <LoginPage /> },
      { path: "privacy-policy", element: <PrivacyPolicyPage /> },
      { path: "terms-of-use", element: <TermsOfUsePage /> },
    ],
  },
  {
    path: "/",
    element: <PrivateRoute adminRequired={false} />,
    children: [
      {
        path: "user",
        element: (
          <Dashboard>
            <Outlet />
          </Dashboard>
        ),
        children: [
          {
            path: "",
            element: <Navigate replace to="apps" />,
          },
          {
            path: "apps",
            element: <AppsOverviewPage />,
          },
          {
            path: "apps/igt",
            element: <IGTPage />,
          },
          {
            path: "apps/pct",
            element: <PCTPage />,
            children: [
              {
                path: "",
                element: <Navigate replace to="pctx1" />,
              },
              {
                path: "pctx1",
                element: <PCTx1 />,
              },
              {
                path: "pctx3",
                element: <PCTx3 />,
              },
              {
                path: "pctcs",
                element: <PCTCS />,
              },
              {
                path: "pctsiü",
                element: <PCTSIÜ />,
              },
              {
                path: "ausschreibungen",
                element: <Tender />,
              },
              {
                path: "msg",
                element: <MSG />,
              },
            ],
          },
          {
            path: "apps/cvc",
            element: <CVCPage />,
            children: [
              {
                path: "",
                element: <Navigate replace to="individuell-ar" />,
              },
              {
                path: "individuell-ar",
                element: <INDAR />,
              },
              {
                path: "extern-ar",
                element: <EXTAR />,
              },
              {
                path: "intern-ar",
                element: <INTAR />,
              },
              {
                path: "extern-ea",
                element: <EXTEA />,
              },
              {
                path: "intern-ea",
                element: <INTEA />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
]);

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

export default function AppRoutes() {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
