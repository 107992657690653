// features/user/Dashboard.tsx
import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import DesktopMenu from "features/user/DesktopMenu";
import MobileMenu from "features/user/MobileMenu";

const Dashboard: React.FC<{
  children: React.ReactNode;
  subMenu?: React.ReactNode;
}> = ({ children, subMenu }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [menuOpen, setMenuOpen] = useState(true);

  useEffect(() => {
    if (isMediumScreen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }, [isMediumScreen]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const mainContentWidth = () => {
    if (isSmallScreen) {
      return `100%`;
    }
    const baseWidth = menuOpen ? 250 : 60;
    const subMenuWidth = subMenu ? 200 : 0;
    return `calc(100% - ${baseWidth + subMenuWidth}px)`;
  };

  const mainContentMarginLeft = () => {
    if (isSmallScreen) {
      return 0;
    }
    const baseMargin = menuOpen ? 250 : 60;
    const subMenuWidth = subMenu ? 200 : 0;
    return `${baseMargin + subMenuWidth}px`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#ffffff",
        minHeight: "100vh",
        pt: 0,
      }}
    >
      {isSmallScreen ? (
        <MobileMenu />
      ) : (
        <DesktopMenu menuOpen={menuOpen} toggleMenu={toggleMenu} />
      )}
      {!isSmallScreen && subMenu && (
        <Box
          sx={{
            width: 200,
            bgcolor: "#f6f6f6",
            position: "fixed",
            height: "100vh",
            overflow: "auto",
            transition: "width 0.3s",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            zIndex: 1250,
            left: menuOpen ? 250 : 60,
            top: 0,
          }}
        >
          {subMenu}
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: mainContentWidth(),
          ml: mainContentMarginLeft(),
          transition: "width 0.3s, margin-left 0.3s",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Dashboard;
